import {
  NutrientSelect,
  useNutrientOptions,
} from "../../../components/Reports/components/ChangeReportSettingsButton";
import { useRecoilValue } from "recoil";
import { reportingStaticSelector } from "../../../selectors";

export function SelectNutrients({ onChange, value, ...props }) {
  const { nutrientGroups } = useRecoilValue(reportingStaticSelector);
  const nutrientOptions = useNutrientOptions({
    nutrientGroups,
    includeAll: true,
  });

  return (
    <NutrientSelect
      explode
      options={nutrientOptions}
      keys={value}
      nutrientGroups={nutrientGroups}
      setKeys={onChange}
    />
  );
}
