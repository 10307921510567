import React, {useState, Suspense} from "react";
import { useParticipantContext } from "../../contexts/useParticipantContext";
import CalorieConsumption from "components/Reports/CalorieConsumption";
import { useParticipantClient, useAsyncEffect } from "hooks";
import StudentReports from "components/Reports/StudentReports";
import { ChangeReportSettingsButton } from "components/Reports/components";
import { useRecoilValue } from "recoil";
import { reportingStaticSelector } from "selectors";
import { Page, Loading } from "components/common";
import { Trans, useTranslation } from "react-i18next";
import { Title } from "./components/Title";
import {ParticipantSustainabilityReport} from "./components/ParticipantSustainabilityReport";
import {TopContributorsReport} from "../../components/Reports/TopContributorsReport";

export const DiarySuccess = () => {
  const { project, date } = useParticipantContext();
  const { show_diary_nutrients } = project;
  const { t } = useTranslation();

  const client = useParticipantClient();
  const [nutrientIntake, setNutrientIntake] = useState();
  const [projectReports, setProjectReports] = useState();
  const [reportNutrients, setReportNutrients] = useState([]);

  const { nutrientGroups } = useRecoilValue(reportingStaticSelector);

  useAsyncEffect(async () => {
    let keys = reportNutrients.reduce((acc, i) => {
      return [...acc, ...i.value.split("|")];
    }, []);

    if (!keys.length)
      keys = [
        "calories",
        "kilojoules",
        "protein",
        "fat",
        "saturated_fat",
        "carbohydrates",
        "total_sugars",
        "salt",
        "fibre",
      ];

    setNutrientIntake(null);
    setProjectReports(null);

    const [nutrientIntake, projectReports] = await Promise.all([
      client
        .post("diary/nutrient_summary_report", {
          keys,
          date
        })
        .get("data"),
      project.is_student_project
        ? client
            .post("diary/project_reports", {
              keys,
            })
            .get("data")
        : null,
    ]);
    setProjectReports(projectReports);
    setNutrientIntake(nutrientIntake);
  }, [reportNutrients]);

  const bottomText = (
    <p className="mb-4">
      <Trans>
        The above nutrient summary information is provided for guidance only and
        is only as accurate as the food and drink information specified
      </Trans>
    </p>
  );

  const base = (children) => (
    <div className="max-w-screen-xl mx-auto">
      <div className="text-center">
        <Title className="leading-none">
          <Trans>Thank You!</Trans>
          <br />
          <span className="text-base">
            <Trans>for participating in myfood24</Trans>
          </span>
        </Title>
        <div></div>
        <div className="my-2">
          {project.project_reference_intake?.heading_statement ? (
            <div
              className="prose"
              dangerouslySetInnerHTML={{
                __html: project.project_reference_intake.heading_statement,
              }}
            />
          ) : (
            <Trans>
              You have successfully submitted your diary and it is now safe to
              close your browser.
            </Trans>
          )}
        </div>
      </div>
      {project.is_student_project ? (
        <div className="flex justify-center my-4">
          <ChangeReportSettingsButton
            reportNutrients={reportNutrients}
            setReportNutrients={setReportNutrients}
            nutrientGroups={nutrientGroups}
          />
        </div>
      ) : null}
      {children}
    </div>
  );

  const hasReports = !!(
    project.is_student_project || project.show_calorie_consumption
  );
  const { participant } = useParticipantContext();
  const { gender } = participant;

  const sustainabilityReport = (project.include_ghge_in_extracts && project.is_en) ? (
    <Suspense fallback={null}>
      <ParticipantSustainabilityReport />
    </Suspense>
  ) : null;

  if (!nutrientIntake)
    return base(
      hasReports ? (
        <Page title={t("Reports")}>
          <Loading />
        </Page>
      ) : null
    );

  return base(
    <>
      {project.is_student_project ? (
        <div>
          <CalorieConsumption
            title={t("Nutrient Intake")}
            data={nutrientIntake.core}
          />
          <TopContributorsReport gender={gender} url={'diary/top_contributors'} client={client} />
          <StudentReports
            nutrientIntake={nutrientIntake}
            projectReports={projectReports}
            sustainabilityReport={sustainabilityReport}
          />
          <div className="container text-left px-6 lg:px-8 text-sm opacity-75 mb-2">
            {bottomText}
            <p>
              <Trans>
                * References based on an adult of healthy weight with a moderate
                physical activity level
              </Trans>
            </p>
          </div>
        </div>
      ) : null}
      {!project.is_student_project && project.show_calorie_consumption ? (
        <div>
          <CalorieConsumption data={nutrientIntake.core} />
          {sustainabilityReport}
          {show_diary_nutrients?.length ? (
              <TopContributorsReport nutrients={show_diary_nutrients} gender={gender} url={'diary/top_contributors'} client={client} />
          ) : null}
          <div className="container text-left px-6 lg:px-8 text-sm opacity-75 mb-2">
            {bottomText}
            {project.project_reference_intake?.bottom_statement ? (
              <div
                className="prose prose-sm"
                dangerouslySetInnerHTML={{
                  __html: project.project_reference_intake.bottom_statement,
                }}
              />
            ) : (
              <p>
                <Trans>
                  * Reference intake values are based on the government dietary recommendations for adults
                </Trans>
              </p>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DiarySuccess;
