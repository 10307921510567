import { Urls } from "./constants";

/**
 * @param {import('axios').AxiosInstance} client
 * @param {string} nutrient_key
 * @returns {Promise<NutrientValues>}
 */
export function getNutrientValues(client, nutrient_key) {
  return client
    .get(Urls.NUTRIENT_VALUES, {
      params: {
        key: nutrient_key,
      },
    })
    .get("data");
}
