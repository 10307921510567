/**
 *
 * @param {NutrientValues | undefined} nutrientValues
 * @param {number | null} [recipeEntry]
 * @param {number | null} [foodEntry]
 * @param {string | null} [meal]
 * @param {boolean | null} [total]
 */
export function getNutrientValue(
  nutrientValues,
  recipeEntry,
  foodEntry,
  meal,
  total,
) {
  if (!nutrientValues) return "";

  if (meal) {
    return `${nutrientValues.meals[meal]} ${nutrientValues.nutrient_unit}`;
  }

  if (foodEntry) {
    const entry = nutrientValues.food_entries[foodEntry];
    return entry != null ? `${entry} ${nutrientValues.nutrient_unit}` : "";
  }

  if (recipeEntry) {
    const entry = nutrientValues.recipe_entries[recipeEntry];
    return entry != null ? `${entry} ${nutrientValues.nutrient_unit}` : "";
  }

  if (total) {
    return `${nutrientValues.total} ${nutrientValues.nutrient_unit}`;
  }
}

/**
 *
 * @param {object} nutrient
 * @param {import('react-i18next').TFunction<string>} t
 * @returns {object}
 */
export function translateNutrient(nutrient, t) {
  return {
    ...nutrient,
    label: t(nutrient.original_label ?? nutrient.label),
    original_label: nutrient.original_label ?? nutrient.label,
  };
}

/**
 *
 * @param {array} nutrients
 * @param {import('react-i18next').TFunction<string>} t
 * @returns {array}
 */
export function translateNutrients(nutrients, t) {
  return nutrients.map((nutrient) => translateNutrient(nutrient, t));
}
