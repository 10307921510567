import { useModal } from "../../hooks";
import {Trans, useTranslation} from "react-i18next";
import { useDiaryProgress } from "./api";
import { Alert, Badge, Button, ModalBody, ModalFooter } from "reactstrap";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import { useEffect, useMemo } from "react";
import { useSetRecoilState } from "recoil";
import { multiDayDateAtom } from "./atoms";
import { formatISODate } from "../../services/formatters";
import moment from "moment";
import { ModalHeader } from "../../Pages/Participant/components/ModalHeader";
import { useNavigate } from "react-router";
import {
  ar,
  da,
  de,
  enGB,
  enUS,
  es,
  fr,
  it,
  nb
} from 'date-fns/locale';

const localeMapping = {
  ar,
  da,
  de,
  en: enGB,
  en_us: enUS,
  es,
  fr,
  it,
  no: nb
}

const dateTransform = {
  input: (v) => moment(v).toDate(),
  output: (v) => formatISODate(v),
};

const Form = ({ data: dataProp }) => {
  const { formState } = useFormContext();
  const { errors } = formState;
  const [in_progress, completed] = useMemo(() => {
    const { in_progress, completed } = dataProp;

    return [
      in_progress.map((i) => moment(i).toDate()),
      completed.map((i) => moment(i).toDate()),
    ];
  }, [dataProp]);
  const { i18n } = useTranslation();

  return (
    <>
      <div className="flex justify-center">
        <Controller
          name="date"
          rules={{
            required: true,
          }}
          render={({ value, onChange }) => (
            <DayPicker
              mode="single"
              captionLayout="dropdown-buttons"
              showOutsideDays
              locale={localeMapping[i18n.language]}
              fixedWeeks
              modifiers={{
                in_progress,
                completed,
              }}
              modifiersStyles={{
                in_progress: {
                  backgroundColor: "#92d1d7",
                },
                completed: {
                  backgroundColor: "#165e5a",
                  color: "white",
                },
                selected: {
                  backgroundColor: "white",
                  color: "black",
                },
              }}
              fromYear={2015}
              toYear={moment().year()}
              selected={dateTransform.input(value)}
              onSelect={(v) => onChange(dateTransform.output(v))}
            />
          )}
        />
      </div>
      <div className="text-center">
        <div>
          <Trans>
            You have{" "}
            <Badge color="brand-light">
              {{ in_progress: in_progress.length }}
            </Badge>{" "}
            diaries in progress
          </Trans>
        </div>
        <div>
          <Trans>
            You have completed{" "}
            <Badge color="brand-dark">{{ completed: completed.length }}</Badge>{" "}
            diaries
          </Trans>
        </div>
      </div>
      {errors.date ? (
        <Alert color="info" className="text-center mb-0 mt-2">
          <Trans>Please select a diary date</Trans>
        </Alert>
      ) : null}
    </>
  );
};

export const DateSelection = () => {
  const { isOpen, setIsOpen, Modal } = useModal({
    initialIsOpen: true,
  });
  const navigate = useNavigate();
  const { data } = useDiaryProgress();
  const form = useForm({
    defaultValues: {},
  });
  const { reset, handleSubmit } = form;
  const setMultiDayDate = useSetRecoilState(multiDayDateAtom);

  useEffect(() => {
    reset();

    if (isOpen) {
      navigate(".");
    }
  }, [reset, isOpen, navigate]);

  function onSubmit({ date }) {
    setIsOpen(false);
    setMultiDayDate(date);
  }

  return (
    <FormProvider {...form}>
      <Modal toggle={null}>
        <ModalHeader>
          <Trans>Select Diary Date</Trans>
        </ModalHeader>
        <ModalBody>
          <p>
            <Trans>
              This project allows you to submit multiple diaries, please choose
              a date for the diary you want to complete.
            </Trans>
          </p>
          {!!data && <Form data={data} />}
        </ModalBody>
        <ModalFooter>
          <Button type="submit" onClick={handleSubmit(onSubmit)}>
            <Trans>Enter Food Diary</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    </FormProvider>
  );
};
