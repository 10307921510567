import { Urls } from "./constants";
import { getNutrientValues } from "./api";

/**
 * @param {import('axios').AxiosInstance} client
 * @param {string} nutrient_key
 * @param {any} [trigger]
 * @returns {import('react-query').UseQueryOptions<NutrientValues>}
 */
export function nutrientValuesQueryOptions(client, nutrient_key, trigger) {
  return {
    queryKey: [Urls.NUTRIENT_VALUES, nutrient_key, trigger],
    queryFn: () => getNutrientValues(client, nutrient_key),
  };
}
